import React from "react";

interface Props{
    errors: any;
}

export default function ValidationErrors({errors}: Props){
    return (
        <p>
            {errors && (
                <ul color='red'>
                    {errors.map((err: any, i: any) => (
                        <li key={i}>{err}</li>
                    ))}
                </ul>
            )}
        </p>
    )
}